<template>
  <v-card>
    <v-tabs vertical>
      <v-tab>
        <v-icon left>{{mdiImagePath}}</v-icon>
        {{$t('views.profile.imgOpticaTitle')}}
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-file-input
              v-bind:label="labelLogoOptica"
              filled
              :prepend-icon="mdiCameraPath"
              @change="onFileChangeLogoOptica"
              accept="image/png"
              v-bind:placeholder="imageNameLO"
              v-bind:background-color="backgroundLO"
            ></v-file-input>
            <v-file-input
              v-bind:label="labelFirmaOptica"
              filled
              :prepend-icon="mdiDrawPath"
              @change="onFileChangeFirmaOptico"
              accept="image/png"
              v-bind:placeholder="imageNameFO"
              v-bind:background-color="backgroundFO"
            ></v-file-input>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab>
        <v-icon left>{{mdiAccountGroupPath}}</v-icon>
        {{$t('views.profile.social.imgSocialTitle')}}
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <div v-for="redsocial in data.redesSociales" :key="redsocial.id">
               <v-text-field
                  :label="redsocial.label"
                  :prepend-icon="redsocial.iconPath"
                  v-model="redsocial.value"
                  :id="redsocial.id"
                  prefix="https://"
               ></v-text-field>
              </div>
          </v-card-text>
           <v-card-actions>
              <v-spacer></v-spacer><v-btn color="secondary" @click="saveSocial">{{$t('common.buttons.save')}}</v-btn>
            </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { toDataFile } from "@/js/utils";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { mdiImage, mdiAccountGroup, mdiCamera, mdiDraw, mdiFacebook, mdiTwitter, mdiInstagram, mdiLinkedin  } from '@mdi/js';
const IMG_LOGO_OPTICA = "LogoOptica";
const IMG_FIRMA_OPTICO = "FirmaOptico";
const COLOR_OK = "#b8f9bf";
const TYPEPNG = "image/png"


export default {
  data: () => ({
    images: [],
    rules: [
      value =>
        !value || value.size < 2000000 || "La imagen no puede superar los 2MB!"
    ],
    backgroundLO: null,
    backgroundFO: null,
    imageNameLO: null,
    imageNameFO: null,
    labelLogoOptica: null,
    labelFirmaOptica: null,
    mdiImagePath: mdiImage,
    mdiAccountGroupPath: mdiAccountGroup,
    mdiCameraPath: mdiCamera,
    mdiDrawPath: mdiDraw,
    data: {
      redesSociales: [
      {
        id: "Facebook",
        iconPath: mdiFacebook,
        label: "Facebook",
        value: null
      },
      {
        id: "Twitter",
        iconPath: mdiTwitter,
        label: "Twitter",
        value: null
      },
      {
        id: "Instagram",
        iconPath: mdiInstagram,
        label: "Instagram",
        value: null
      },
      {
        id: "Linkedin",
        iconPath: mdiLinkedin,
        label: "Linkedin",
        value: null
      },
    ]
    },
  }),
  methods: {
    ...mapActions("images", {
      getImages: "find",
      saveImage: "save",
      deleteImage: "destroy"
    }),
    ...mapActions("customer", {
      save: "save",
      find: "find"
    }),
    ...mapActions("notifications", ["success", "info", "error"]),
    onFileChangeLogoOptica(image) {
      if (image) {
        if (image.type !== TYPEPNG){
          this.info(this.$t("views.profile.onlyPng"))
          return
        }
        this.backgroundLO = COLOR_OK;
        this.upload("L", image);
      }
    },
    onFileChangeFirmaOptico(image) {
      if (image) {
        if (image.type !== TYPEPNG){
          this.info(this.$t("views.profile.onlyPng"))
          return
        }
        this.backgroundFO = COLOR_OK;
        this.upload("F", image);
      }
    },
    getNameDefault(key, name) {
      let extension;

      if (name) {
        let nameSplited = name.split(".");
        extension = nameSplited[nameSplited.length - 1];
      }

      extension = extension.toLowerCase();

      switch (key) {
        case "L":
          return `${IMG_LOGO_OPTICA}.${extension}`;
          break;
        case "F":
          return `${IMG_FIRMA_OPTICO}.${extension}`;
          break;
        default:
          break;
      }
    },
    checkExistsImage(key, image) {
      let defaultNameImg = this.getNameDefault(key, image.name);

      for (let i in this.images) {
        let img = this.images[i];
        if (_.includes(img.src, defaultNameImg)) {
          return { existsImg: true, idImg: img.id, imgName: defaultNameImg };
        }
      }
      return { existsImg: false, idImg: null, imgName: defaultNameImg };
    },
    checkOkImages(){
      for (let i in this.images) {
        let img = this.images[i];
        if (_.includes(img.category, 'L')) {
          this.backgroundLO = COLOR_OK;
          this.imageNameLO = this.getNameDefault('L', img.src);
          continue;
        }
        if (_.includes(img.category, 'F')) {
          this.backgroundFO = COLOR_OK;
          this.imageNameFO = this.getNameDefault('F', img.src);
          continue;
        }
      }
    },
    upload(key, image) {
      let objResult = this.checkExistsImage(key, image);

      toDataFile(image).then(dataB64 => {
        this.saveImage({id: objResult.existsImg ? objResult.idImg : null, name: objResult.imgName, category: key, data: dataB64})
          .then(image => {
            this.success(this.$t("views.profile.uploaded"));
          })
          .catch(error => this.error(error.message));
      });
    },
    async saveSocial(){
      try {
        let saved = await this.save(this.data);
        if(saved){
          this.success(this.$t('views.profile.profileSaved'));
        }
      } catch (error) {
          this.error(error.message);
      }
    }
  },
  async mounted() {
    try {
      this.$store.dispatch("setTitle", "Perfil de usuario");
      this.labelLogoOptica = this.$t("views.profile.logoOptica");
      this.labelFirmaOptica = this.$t("views.profile.firmaOptica");

      let images = await this.getImages("category=L,F");
      if(images){
        this.images = images;
        this.checkOkImages();
      }
    
      let customerData = await this.find();
      if (customerData){
        this.data = customerData;
      }  
    } catch (error) {
       this.error(error.message);
    }
  },
};
</script>

<style>
</style>